<template>
  <div
    v-click-outside="closeDropdownList"
    class="vb-dropdown-search container"
    :style="{ width: width + 'px', maxHeight: maxHeight + 'px' }"
  >
    <div class="vb-dropdown-button" @click="toggleDropdownListVisible">
      <div
        style="
          margin-left: 12px;
          font-size: 15px;
          font-weight: 500;
          margin-right: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        "
        :style="{ color: selectedData ? '#263373' : '#9ca6c9' }"
      >
        {{ buttonTitle }}
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          position: relative;
          right: 4px;
          margin-left: auto;
        "
      >
        <img
          v-if="selectedData && isDeleteEnabled"
          src="../assets/ic-clear.svg"
          @click.stop="onClickClearButton"
        />
        <img v-else src="../assets/ic-triangle-dropdown.svg" />
      </div>
    </div>
    <VBDropdownSearchPopover
      v-if="isDropdownListVisible"
      :width="width"
      :max-height="maxHeight"
      :cell-style="cellStyle"
      :data-list="dataList"
      :selected-data="selectedData"
      :is-search-text-field-hidden="isSearchTextFieldHidden"
      @onSelectData="onSelectData"
    ></VBDropdownSearchPopover>
  </div>
</template>

<script>
import VBDropdownSearchPopover from '@/components/custom-views/vb-dropdown-search/classes/vb-dropdown-search-popover.vue';
import { cellStyleConstants } from './vb-dropdown-search-constant';

export default {
  name: 'VbDropdownSearch',
  components: { VBDropdownSearchPopover },
  props: {
    width: {
      type: Number,
      default: 210,
    },
    maxHeight: {
      type: Number,
      default: 280,
    },
    dataList: {
      type: Array,
      default: null,
    },
    selectedData: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    cellStyle: {
      type: String,
      default: cellStyleConstants.default,
    },
    isSearchTextFieldHidden: {
      type: Boolean,
      default: false,
    },
    isDeleteEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDropdownListVisible: false,
    };
  },
  computed: {
    buttonTitle() {
      if (this.selectedData) {
        if (this.cellStyle == cellStyleConstants.subtitle) {
          return `${this.selectedData.title} ${this.selectedData.subtitle}`;
        } else {
          return this.selectedData.title;
        }
      }
      return this.placeholder;
    },
  },
  watch: {},
  methods: {
    onClickClearButton() {
      this.$emit('onClearSelection');
    },
    closeDropdownList() {
      this.isDropdownListVisible = false;
    },
    toggleDropdownListVisible() {
      this.isDropdownListVisible = !this.isDropdownListVisible;
    },
    onSelectData(data) {
      this.$emit('onSelectData', data);
      this.closeDropdownList();
    },
  },
};
</script>

<style lang="less" scoped>
.vb-dropdown-search.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: transparent;
  position: relative;
}

.vb-dropdown-button {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  background-color: #edeff7;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
