<template>
  <div
    class="vb-dropdown-search-popover container"
    :style="{ maxHeight: maxHeight + 'px', width: width + 'px' }"
  >
    <div style="display: flex; flex-direction: column">
      <div v-if="!isSearchTextFieldHidden" class="search-textfield-container">
        <img src="../assets/ic-search.svg" style="width: 24px; height: 24px; margin: 8px" />
        <div style="display: flex; width: 100%; margin-right: 4px">
          <input v-model="searchText" type="text" autocomplete="off" style="width: 100%" />
        </div>
        <img
          v-if="searchText"
          src="../assets/ic-clear.svg"
          class="clear-button"
          @click="onClickClearButton"
        />
      </div>
      <div
        :style="{
          display: 'block',
          overflow: 'auto',
          width: '100%',
          height: `${tableHeight}px`,
        }"
      >
        <table ref="table" cellspacing="0" cellpadding="0" style="width: 100%">
          <tbody style="display: block">
            <tr
              v-for="data in filteredDataList"
              ref="rows"
              :key="data.id"
              style="display: flex; min-height: 40px; width: 100%"
            >
              <td style="width: 100%">
                <div
                  :class="isSelectedData(data) ? 'cell-selected' : ''"
                  class="cell"
                  @click="onSelectData(data)"
                >
                  <div class="cell-title">
                    {{ data.title }}
                  </div>
                  <div v-if="isCellStyleSubtitle" class="cell-subtitle">
                    {{ data.subtitle }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { filter } from 'lodash';
import { cellStyleConstants } from './vb-dropdown-search-constant';

export default {
  name: 'VbDropdownSearchPopover',
  components: {},
  props: {
    height: {
      type: Number,
      default: 256,
    },
    width: {
      type: Number,
      default: 210,
    },
    cellStyle: {
      type: String,
      default: cellStyleConstants.default,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
    isSearchTextFieldHidden: {
      type: Boolean,
      default: false,
    },
    selectedData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      tableHeight: 0,
    };
  },
  computed: {
    isCellStyleSubtitle() {
      return this.cellStyle == cellStyleConstants.subtitle;
    },
    filteredDataList() {
      const searchText = this.searchText.trim().toLowerCase();
      if (searchText.length > 0) {
        return filter(this.dataList, data => {
          if (this.isCellStyleSubtitle) {
            return (
              (data.id &&
                data.id
                  .toString()
                  .toLowerCase()
                  .includes(searchText)) ||
              (data.title && data.title.toLowerCase().includes(searchText)) ||
              (data.subtitle && data.subtitle.toLowerCase().includes(searchText))
            );
          } else {
            return (
              (data.id &&
                data.id
                  .toString()
                  .toLowerCase()
                  .includes(searchText)) ||
              (data.title && data.title.toLowerCase().includes(searchText))
            );
          }
        });
      }
      return this.dataList;
    },
  },
  watch: {
    cellStyle() {
      this.updateTableHeight();
    },
    dataList() {
      this.updateTableHeight();
    },
  },
  mounted() {
    this.updateTableHeight();
  },
  methods: {
    isSelectedData(data) {
      if (this.selectedData) {
        return this.selectedData.id == data.id;
      }
      return false;
    },
    onClickClearButton() {
      this.searchText = '';
    },
    updateTableHeight() {
      const table = this.$refs.table;
      if (table) {
        const height = this.maxHeight - 56 - 48;
        if (table.clientHeight < height) {
          this.tableHeight = table.clientHeight + 1;
        } else {
          this.tableHeight = height;
        }
      }
    },
    onSelectData(data) {
      this.$emit('onSelectData', data);
    },
  },
};
</script>

<style lang="less" scoped>
input {
  font-size: 15px;
  font-weight: 500;
  color: #263373;
  background: transparent;
  border: none;
  padding: 0;
}

input:focus {
  outline: none;
}

.vb-dropdown-search-popover.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 0 8px 0 #edeff7;
  width: 242px;
  position: absolute;
  top: 48px;
  z-index: 22;
}

.search-textfield-container {
  display: flex;
  margin: 8px;
  background: #edeff7;
  border-radius: 8px;
  height: 40px;
  align-items: center;
}

.clear-button {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  cursor: pointer;
}

.cell {
  font-size: 15px;
  font-weight: 500;
  color: #263373;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
  height: 100%;
  border-bottom: solid 1px #dbe0f0;
}

.cell-title {
  font-weight: 600;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  word-break: break-word;
}

.cell-subtitle {
  font-weight: 500;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: -4px;
  margin-bottom: 4px;
  word-break: break-word;
}

.cell:hover {
  background: #e5e9ff;
  cursor: pointer;
}

.cell-selected {
  background: #e5e9ff;
}
</style>
